import { useTranslate } from '/machinery/I18n'

import { ContainerXl } from '/features/buildingBlocks/Container'
import { SliderWithText } from '/features/pageOnly/SliderWithText'

import styles from './FeaturedClients.css'

export function FeaturedClients({ title, description, items, layoutClassName }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <ContainerXl>
        <SliderWithText interactionTitle='sectors-slider' {...{ title, description }}>
          {items.map(({ title, logo, url }, i) => (url
            ? <CardAnchor key={i} {...{ title, logo, url }} />
            : <Card key={i} {...{ logo }} />
          ))}
        </SliderWithText>
      </ContainerXl>
    </div>
  )
}

function Card({ logo }) {
  return (
    <div className={cx(styles.componentCard, styles.component_rootCard)}>
      <div className={styles.cardContainer}>
        {logo?.asset && <Logo layoutClassName={styles.logoLayout} {...{ logo }} />}
      </div>
    </div>
  )
}

function CardAnchor({ title, logo, url }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentCardAnchor, styles.component_rootCardAnchor)}>
      <a href={url} data-x='link-to-client' target='_blank' rel="noreferrer" aria-label={`${__`external-link-to`} ${title}`} className={styles.cardAnchor}>
        {logo?.asset && <Logo layoutClassName={styles.logoLayout} {...{ logo }} />}
      </a>
    </div>
  )
}

function Logo({ logo, layoutClassName }) {
  return <div style={{ maskImage: `url(${logo.asset.url})` }} className={cx(styles.componentLogo, layoutClassName)} />
}
